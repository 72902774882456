"use strict";
import axios from "axios";
let headers = {
    // 'Content-Type': 'application/x-www-form-urlencoded'
}
let baseUrl = process.env.VUE_APP_ROOT_URL_ENV; //这里是一个默认的url，可以没有
const request = axios.create({
    baseURL: baseUrl,
    timeout: 1000 * 7,
    headers: headers
})
request.interceptors.request.use(
    (config) => {
        if (localStorage.getItem("fortressExpiredTime") && new Date(Date.parse(localStorage.getItem("fortressExpiredTime"))) < new Date()) {
            //通过refreshToken重新获取token
            let promisefresh = new Promise(function(resolve, reject) {
                //刷新token
                axios.post(baseUrl + "memberRefreshToken", {}, {
                    headers: {
                        'token': '' + localStorage.getItem("fortressRefreshToken")
                    }
                }).then(response => {
                    if (response.data && response.data.code == 0) {
                        localStorage.setItem(
                            "fortressRefreshToken",
                            response.data.data.refreshToken
                        );
                        localStorage.setItem(
                            "fortressExpiredTime",
                            response.data.data.expiredTime
                        );
                        localStorage.setItem(
                            "token",
                            response.data.data.token
                        );
                        config.headers.Authorization = `${response.data.data.token}`;
                    }
                    resolve(config);
                }).catch(() => {
                    reject()
                })
            });
            return promisefresh;
        }
        return config
    },
    () => {}
)
request.interceptors.response.use(
    (response) => {
        if (response.status == 200) {
            if (response.data.code == 0) {
                return new Promise((res) => {
                    res(response.data.data)
                })
            }
            if (response.data.code == 401) {
                this.$router.push('/login')
            }
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)
export default request
