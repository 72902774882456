import request from "./request";
//接口分类
export const getCommodityClassification = () => {
        return request({
            url: "/api/shop/shopInterfaceType/list",
            methods: "get",
        })
    }
    // 接口商品表分页
export const getCommodityPage = (data) => {
        return request({
            url: "/api/shop/shopInterface/page",
            methods: "get",
            params: data
        })
    }
    // 接口商品详情
export const getFaceInfo = (data) => {
        return request({
            url: "/api/shop/shopInterface/info",
            methods: "get",
            params: data
        })
    }
    // 获取接口规格
export const getSpecsInfo = (data) => {
    return request({
        url: "/api/shop/shopSpecs/listByGoods",
        methods: "get",
        params: data
    })
};

// 创建订单
export const saveOrder = (data) => {
    return request({
        url: "/api/order/shopOrder/saveOrder",
        methods: "post",
        params: data
    })
};
//获取协议
export const getAgreement = (data) => {
    return request({
        url: "/api/article/article/infoByCode",
        methods: "get",
        params: data
    })
};